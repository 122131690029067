<template>
  <nav
    id="navbarExample"
    class="navbar navbar-expand-lg fixed-top navbar-light"
    aria-label="Main navigation"
  >
    <div class="container">
      <a class="navbar-brand logo-text" href="#"
        ><img class="mr-3" style="height: 30px" src="trstlabs.svg"
      /></a>

      <button
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse offcanvas-collapse"
        id="navbarsExampleDefault"
      >
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#header"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#details">Details</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#features">Features</a>
          </li> -->
          <!-- <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Drop</a>
                  <ul class="dropdown-menu" aria-labelledby="dropdown01">
                      <li><a class="dropdown-item" href="#">Article Details</a></li>
                      <li><div class="dropdown-divider"></div></li>
                      <li><a class="dropdown-item" href="#">Terms Conditions</a></li>
                      <li><div class="dropdown-divider"></div></li>
                      <li><a class="dropdown-item" href="#">Privacy Policy</a></li>
                  </ul>
              </li> -->
        </ul>
        <span class="nav-item">
          <a class="btn-solid-sm" href="#contact">Get In touch</a>
        </span>
      </div>
    </div>
  </nav>
  <header id="header" class="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <h1 class="h1-large">Innovating Today for a Better Tomorrow</h1>
            <p class="p-large">
              We're developing trustless and non-custiodial
              solutions to blockchain services
            </p>
            <a class="btn-solid-lg" href="#services">What we do</a>
          </div>
        </div>
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/module.png"
              alt="alternative"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <div id="services" class="cards-1 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>Builders of scalable automation solutions</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-icon">
              <div class="row">
                <img
                  class="mb-1"
                  src="https://cryptologos.cc/logos/cosmos-atom-logo.png?v=025"
                />
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title">Cosmos Stack</h5>
              <p>
                We are builders with the Cosmos Stack. It is the most versatile
                and innovative stack for building modular solutions. The cosmos
                stack allows chains to connect in a trustless manner with IBC.
                Blockchains optimize for specific use cases, similar to how
                databases are optimized.
              </p>
              <a
                class="read-more no-line"
                href="https://cosmos.network/"
                target="_blank"
                >Learn more <span class="fas fa-long-arrow-alt-right"></span
              ></a>
            </div>
          </div>

          <div class="card">
            <div class="card-icon red"><span class="fas fa-cubes"></span></div>
            <div class="card-body">
              <h5 class="card-title">Building Modules</h5>
              <p>
                The Cosmos SDK is modular. Chains can plug-and-play and
                integrate any module. We build modules and infrastructure that
                abstract tasks and execution away from users. Users should be
                able to specify their intent just once and the chain takes care
                of it. Web3 needst to be better than traditional finance.
              </p>
              <a
                class="read-more no-line"
                target="_blank"
                href="https://docs.cosmos.network/main/building-modules/intro"
                >Learn more <span class="fas fa-long-arrow-alt-right"></span
              ></a>
            </div>
          </div>

          <div class="card">
            <div class="card-icon green">
              <span class="far fa-comments"></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">Active Community Participants</h5>
              <p>
                We aim to be active in the Cosmos community. We enjoy going to
                community events and meet fellow community members. We
                appreciate feedback, and are always open to iterate our products
                and explore new ideas.
              </p>
              <a
                class="read-more no-line"
                target="_blank"
                href="https://cosmoverse.org/"
                >Learn more <span class="fas fa-long-arrow-alt-right"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="details" class="basic-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/intents.png"
              alt="alternative"
            />
          </div>
        </div>
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <div class="section-title">WHAT WE DO</div>
            <h2>Automation and Intents</h2>
            <p>
              In traditional finance, time-based actions are built-into end-user
              apps. You can set up periodic payment, sign up for subscriptions,
              DCA and so on. You may want to perform multiple actions, but these
              depend on outputs from an event that did not occur yet, in other
              words you intend to perform an action in the future.
            </p>
            <p>
              In Web3, dApp developers have limited options for programming
              these time-based tasks. Current automation solutions are
              off-chain, which are inefficient, in-transparent to end-users and
              can be a central point of failure.
            </p>
            <p>
              We build intent-centric automation infrastructure that is
              efficient, reliable and easy to integrate. Perform time-based
              actions just like in traditional finance.
            </p>
            <a class="btn-solid-reg" href="#contact">Get In touch</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="basic-2">
    <div class="container">
      <div class="row">
        <div>
          <div class="text-container">
            <div class="section-title">ABOUT US</div>
            <h2>
              We have 3+ years of experience on building Cosmos SDK Modules
            </h2>
            <p style="line-height: 3">
              At our core, we are driven by the pursuit of innovation to create
              a better future. Through our intent-centric automation module, we
              are breaking new ground in the realm of trustless solutions. By
              building non-custodial on-chain and permissionless solutions, we
              empower users to take full control of their assets and
              transactions, eliminating the need for intermediaries and enabling
              a more secure and transparent ecosystem.
            </p>
            <!--   <a class="btn-outline-reg" href="#">Details</a> -->
          </div>
        </div>
        <!--  <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/details-2.png"
              alt="alternative"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div id="features" class="accordion-1">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <h2 class="h2-heading">Set and Automate Actions on TriggerPortal</h2>
          <p class="p-heading">
            Build strategies, schedule a token launch or governance proposal.
            Any action can be set, monitored and updated in a seamless manner.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-icon">
                <span class="fas fa-tv"></span>
              </div>
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Feature-rich browser-based environment
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  View and manage actions, copy existing actions, set and forget
                  actions.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-icon blue">
                <span class="fas fa-robot"></span>
              </div>
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Cosmonaut GPT helps you out!
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <a
                    target="_blank"
                    href="https://chat.openai.com/g/g-cRhoPo6YH-cosmonaut"
                  >
                    Cosmonaut</a
                  >
                  Is a GPT agent that knows everything about Cosmos messages,
                  IBC and mainnet chains. It is your best friend when it comes
                  to generating actions.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-icon purple">
                <span class="fas fa-arrow-right"></span>
              </div>
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Scheduled payments and recurrences
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Send tokens with the Coin Sender. It allows you to seamlessly
                  set up a payment stream, on-chain.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-icon orange">
                <span class="fas fa-tools"></span>
              </div>
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Automate Whatever
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Possibilities are endless, automate any type of blockchain
                  message. This includes EVM messages and extends to Ethereum!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-7">
          <div class="image-container">
            <a target="_blank" href="https://triggerportal.zone">
              <img
                class="img-fluid"
                src="assets/images/triggerportal-dashboard.png"
                alt="alternative"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="cards-2 bg-gray">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <h2 class="h2-heading">Customer testimonials</h2>
          </div> 
      </div>
      <div class="row">
          <div class="col-lg-12">
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                      <div class="testimonial-author">Roe Smith</div>
                      <div class="occupation">General Manager, Presentop</div>
                  </div>
                  <div class="gradient-floor red-to-blue"></div>
              </div>
            
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                      <div class="testimonial-author">Sam Bloom</div>
                      <div class="occupation">General Manager, Presentop</div>
                  </div>
                  <div class="gradient-floor blue-to-purple"></div>
              </div>
            
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                      <div class="testimonial-author">Bill McKenzie</div>
                      <div class="occupation">General Manager, Presentop</div>
                  </div>
                  <div class="gradient-floor purple-to-green"></div>
              </div>
            
              
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                      <div class="testimonial-author">Vanya Dropper</div>
                      <div class="occupation">General Manager, Presentop</div>
                  </div>
                  <div class="gradient-floor red-to-blue"></div>
              </div>
            
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                      <div class="testimonial-author">Lefty Brown</div>
                      <div class="occupation">General Manager, Presentop</div>
                  </div>
                  <div class="gradient-floor blue-to-purple"></div>
              </div>
            
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Suspendisse vitae enim arcu. Aliqu convallis risus a felis blandit, at mollis nisi bibendum aliquam noto ricos</p>
                      <div class="testimonial-author">Susane Blake</div>
                      <div class="occupation">General Manager, Presentop</div>
                  </div>
                  <div class="gradient-floor purple-to-green"></div>
              </div>
            
          </div> 
      </div>
  </div> 
</div>  -->
  <!--  <div class="slider-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4>
            Trusted by over <span class="blue">5000</span> customers worldwide
          </h4>
          <hr class="section-divider" />

          <div class="slider-container">
            <div class="swiper-container image-slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="assets/images/customer-logo-1.png"
                    alt="alternative"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="assets/images/customer-logo-2.png"
                    alt="alternative"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="assets/images/customer-logo-3.png"
                    alt="alternative"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="assets/images/customer-logo-4.png"
                    alt="alternative"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="assets/images/customer-logo-5.png"
                    alt="alternative"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="assets/images/customer-logo-6.png"
                    alt="alternative"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="basic-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-container">
            <h2>Find the right developer team for your Cosmos SDK stack</h2>
            <p class="p-large">We're here to contribute.</p>
            <a class="btn-solid-lg" href="#contact">Get free quote</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="contact" class="form-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="text-container">
            <div class="section-title">GET IN TOUCH</div>
            <h2>Contact us for a quote</h2>
            <p>
              Unlock the power of blockchain technology. Get a personalized
              quote from TRST Labs, experts in trustless solutions, Cosmos SDK
              modules, and intent-centric automation. Discover innovative
              solutions tailored to your needs and pave the way for a more
              secure, transparent, and inclusive future.
            </p>
            <ul class="list-unstyled li-space-lg">
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1">
                  Building trustless and inclusive solutions
                </div>
              </li>
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1">Experienced in Cosmos SDK modules</div>
              </li>
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1">
                  Innovators in intent-centric automation
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <form>
            <div class="form-group">
              <input
                v-model="emailContent.subject"
                v-on:keyup="updateOutputUrl"
                class="form-control form-control-alternative"
                placeholder="Subject"
                required
              />
            </div>
            <div class="form-group">
              <textarea
                class="form-control-input"
                placeholder="Type a message..."
                required
                v-model="emailContent.body"
                v-on:keyup="updateOutputUrl"
              />
            </div>
            <div class="form-group">
              <button
                @click="submitForm"
                type="button"
                aria-pressed="true"
                class="form-control-submit-button"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="footer bg-gray">
    <img
      class="decoration-circles"
      src="assets/images/decoration-circles.png"
      alt="alternative"
    />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4>
            If you want to find out what we are building then consider following
            us
          </h4>
          <div class="social-container">
            <span class="fa-stack">
              <a target="_blank" href="https://twitter.com/trstlabs">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-twitter fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/trst-labs"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-linkedin fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a target="_blank" href="https://discord.com/invite/twy8cm2Mbr">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-discord fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a target="_blank" href="https://t.me/sietsegreijdanus">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-telegram fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a
                target="_blank"
                href="https://www.youtube.com/@trst-trustlesshub9570"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-youtube fa-stack-1x"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <ul class="list-unstyled li-space-lg p-small">
            <li><a href="#">Article Details</a></li>
            <li><a href="#">Terms & Conditions</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <p class="p-small statement">
            Copyright © <a href="#">TRST Labs </a>
          </p>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <p class="p-small statement">
            Distributed by
            <a href="https://masukmia.com" target="_blank">TRST Labs </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <button id="myBtn">
    <img src="assets/images/up-arrow.png" alt="alternative" />
  </button>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
<script>
export default {
  data() {
    return {
      emailContent: {
        subject: "",
        body: "",
      },
    };
  },
  methods: {
    submitForm() {
      const emailTo = "info@trstlabs.xyz";
      const emailKeys = Object.keys(this.emailContent);
      const remaining = emailKeys.filter(
        (key) => this.emailContent[key].trim().length > 0
      );
      let outputUrl = "mailto:" + emailTo;
      if (remaining.length > 0) {
        outputUrl += "?";
      }
      outputUrl += remaining
        .map(
          (key) => `${key}=${encodeURIComponent(this.emailContent[key].trim())}`
        )
        .join("&");

      window.location.href = outputUrl;
    },
  },
};
</script>
